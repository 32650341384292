* {
    font-family: 'Neue Machina';
}

// CUSTOM PROPERTIES

:root {
    --font-family: 'Neue Machina';
    --font-family-bold: 'Neue Machina Bold';
    --font-family-light: 'Neue Machina Light';
}

.light {
    --text-color: #000;
    --text-color-reverse: #fff;
    --link-color: #000;
}

// FONTS

@font-face {
    font-family: 'Neue Machina';
    src: local('Neue Machina-Regular'), url(../assets/fonts/NeueMachina-Regular.woff) format('woff');
}

@font-face {
    font-family: 'Neue Machina Bold';
    src: local('Neue Machina-Ultrabold'), url(../assets/fonts/NeueMachina-Ultrabold.woff) format('woff');
}

@font-face {
    font-family: 'Neue Machina Light';
    src: local('Neue Machina-Light'), url(../assets/fonts/NeueMachina-Light.woff) format('woff');
}

// HEADINGS

h1, h2, h3, p {
    color: #000;
}

h1 {
    font-size: 52px;
    font-family: var(--font-family-bold);
}

h3 {
    font-size: 28px;
    line-height: 36px;
    font-family: var(--font-family-light);
}

p {
    font-size: 14px;
    font-family: var(--font-family-light);
}

// EFFECTS

.squiggle {
    background: linear-gradient(to bottom, var(--link-color) 0%, var(--link-color) 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 3px 3px;
    color: #000;
    text-decoration: none;

    &:hover {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
        background-position: 0 100%;
        background-size: auto 6px;
        background-repeat: repeat-x;
        text-decoration: none;
        cursor: pointer;
    }
}
