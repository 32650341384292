.hero {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: -130px;

    @media screen and (max-width: 768px) {
       flex-direction: column-reverse;
       align-items: center;
       margin-bottom: unset;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 36px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 48px;
            margin-left: unset;
         }

        h3 {
            margin-top: 6px;
        }

        button {
            margin-top: 12px;
            z-index: 3;
        }
    }

    &__img {
        max-width: 30%;
        height: 100%;
        z-index: 3;
        transform: rotate(-2deg);

        @media screen and (max-width: 768px) {
            max-width: 60%;
            margin-bottom: -100px;
         }
    }
}