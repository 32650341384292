@import '../../../styles/global.scss';

button {
    /* add space between the text and border of the button */
    padding: 8px 16px;
    /* update background color */
    background: var(--text-color-reverse);
    /* increase text size */
    font-size: 16px;
    /* update text color */
    color: var(--text-color);
    /* add a colored border to the button */
    border: 2px solid var(--text-color);
    /* make the button corners rounded */
    border-radius: 5px;
    box-shadow: 5px 5px var(--text-color);
    position: relative;
    width: fit-content;
  }
  
  button:hover {
    /* update cursor appearance */
    cursor: pointer;
    /* move the button to the left 2px */
    left: -2px;
    /* move the button up 2px */
    top: -2px;
    /* increase the shadow offset */
    box-shadow: 7px 7px var(--text-color);
  }
  
  button:active {
    /* move the button to the right 1px */
    left: 1px;
    /* move the button down 1px */
    top: 1px;
    box-shadow: 4px 4px var(--text-color);
  }